:root {
	--white-web-space: #f4f4f4;

	--violet-dark-web-space: #512bd9;
	--violet-light-web-space: #8f49f2;

	--violet-dark-gradient-opacity10-web-space-t: #8f49f21a;
	--violet-dark-gradient-opacity10-web-space-b: #512bd91a;
	--violet-dark-gradient-opacity10-web-space: linear-gradient(
		to bottom,
		var(--violet-dark-gradient-opacity10-web-space-t) 30%,
		var(--violet-dark-gradient-opacity10-web-space-b) 70%
	);

	--violet-light-1: #8f49f2;
	--violet-light-2: #512bd9;
	--violet-light-gradient: linear-gradient(
		180deg,
		var(--violet-light-1) 0%,
		var(--violet-light-2) 100%
	);
}
